import * as React from "react"
import { useEffect} from "react";
import Header from "../../components/Header"
import Navbar from "../../components/Navbar/Navbar"
import Footer from "../../components/Footer/Footer"
import PictureSea from "../../assets/about_photos/about_3@2x.png"
import PictureLast from "../../assets/about_photos/about-last.jpg"
import PictureStreetCross from "../../assets/about_photos/about_6@2x.png"
import PictureParty from "../../assets/about_photos/about_8@2x.png"
import PictureDrone from "../../assets/about_photos/team_4@2x.png"
import { useTranslation } from "../../i18n"
import { Helmet } from "react-helmet"
import i18n from "i18next"

const About = () => {
	const { t } = useTranslation("about")
	useEffect(() => {
		i18n.changeLanguage("jp")
		  },
	[]);
	return (
		<div>
			<div className="bg-tofuLightGrey flex flex-col items-center">
				<Navbar />
				<Header title="ABOUT" description="" />
				<Helmet>
					<title>TOFU Media</title>
					<meta name="description" content={ "TOFU Mediaの変遷と理念をこちらのページでご紹介しております。「日本各地で質の高い、示唆に富んだ作品の撮影・制作をサポートするプロダクションサービスをお届けする」という目標を掲げ、日々精進して参ります。"} />
				</Helmet>
				<div className="w-[90%] h-auto mt-24">
					<div className="w-[100%] flex flex-col-reverse items-center lg:flex-row lg:justify-between h-auto ">
						<div
							className="w-[100%] lg:w-4/12 flex flex-col justify-center text-tofuLightWhite font-normal font-syne text-lg lg:text-md leading-tight mt-12 lg:mt-20 2xl:text-xl"
							dangerouslySetInnerHTML={{ __html: t("1") }}
						></div>

						<img src={PictureStreetCross} className="w-[100%] lg:w-7/12" />
					</div>
					<div className="w-[100%] flex flex-col items-center lg:flex-row lg:justify-between h-auto  mt-12 lg:mt-36">
						<img src={PictureSea} className="w-[100%] lg:w-7/12" />
						<div
							className="w-[100%] lg:w-4/12 flex flex-col justify-center text-tofuLightWhite font-normal font-syne text-lg lg:text-md leading-tight mt-12 lg:mt-20 2xl:text-xl"
							dangerouslySetInnerHTML={{ __html: t("2") }}
						></div>
					</div>
					<div className="w-[100%] flex flex-col-reverse items-center lg:flex-row lg:justify-between h-auto  mt-12 lg:mt-36">
						<img src={PictureDrone} className="w-[100%]" />
					</div>
					<div className="w-12/12 lg:w-10/12 flex flex-col-reverse items-center lg:flex-row lg:justify-between h-auto mt-12 lg:mt-32 mx-auto">
						<div
							className="w-[100%] lg:w-[40%] flex flex-col justify-center text-tofuLightWhite font-normal font-syne text-lg lg:text-md leading-tight mt-12 lg:mt-20 2xl:text-xl"
							dangerouslySetInnerHTML={{ __html: t("3") }}
						></div>
						<img src={PictureParty} className="w-[100%] lg:w-[45%]" />
					</div>
					<div className="w-12/12 flex flex-col items-center lg:flex-row lg:justify-between h-auto mt-16 lg:mt-32">
						<img src={PictureLast} className="w-12/12 lg:w-6/12" />
						<div
							className="w-12/12 lg:w-4/12 flex flex-col justify-center text-tofuLightWhite font-normal font-syne text-lg lg:text-md leading-tight mt-12  2xl:text-xl"
							dangerouslySetInnerHTML={{ __html: t("4") }}
						></div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	)
}

export default About
